export interface DataSourceSchema {
    key: string;
    type: string;
    label: string;
    readonly?: boolean;
    required?: boolean;
}

export interface DrillData {
    domainId: number;
    subdomainId: number;
    siteId: number;
    apiV2DatasetId: number;
    blast: string;
    isRecordExist: boolean;
    blastName: string;
    blastDate: Date;
    blastLocation: string;
    blaster: string;
    totalBoosters:number;
    totalLabors:number;
    totalHoursWorked:number;
    trucks:string[];

    holeCount: number;
    averageHoleDiameter: number;
    averageHoleLength: number;
    totalDrillLength: number;
    totalDrillingDepth: number;
    averageBenchHeight: number;
    averagePatternBurden: number;
    averageSpacing: number;
    averageSubdrill: number;

    totalVolume: number;
    powderFactorVolume: number;
    powderFactorWeight: number;
    tonnage: number;
    maxNumberOfChargesPer8ms: number;
    totalNumberOfDetonators: number;
}

export const drillDataSourceCollectionSchema: DataSourceSchema[] = [
    // { key: 'datasetName', type: 'text', label: 'Project', readonly: true },
    // { key: 'blastReportUrl', type: 'text', label: 'Blast Report URL', readonly: true },
    { key: 'shotNumber', type: 'text', label: 'Blast #' },
    { key: 'dateTime', type: 'date', label: 'Blast Date' },
    { key: 'locationOfBlast', type: 'text', label: 'Blast Location' },
    { key: 'blasterName', type: 'text', label: 'Blaster' },
    { key: 'totalLabors', type: 'number', label: 'Total No. Of Labors',readonly: true },
    { key: 'totalWorkHours', type: 'number', label: 'Total No. Of Hours Worked',readonly: true },
    { key: 'totalBoosters', type: 'number', label: 'Total No. Of Boosters',readonly: true },
    { key: 'holeCount', type: 'number', label: 'Total Hole Count' },
    { key: 'averageHoleDiameter', type: 'number', label: 'Average Hole Diameter', readonly: true },
    { key: 'averageHoleLength', type: 'number', label: 'Average Hole Length', readonly: true },
    { key: 'totalDrillLength', type: 'number', label: 'Total Drill Length' },
    { key: 'totalDrillDepth', type: 'number', label: 'Total Drilling Depth' },
    { key: 'averageBenchHeight', type: 'number', label: 'Average Bench Height', readonly: true },
    { key: 'averagePatternBurden', type: 'number', label: 'Average Pattern Burden', readonly: true },
    { key: 'averageSpacing', type: 'number', label: 'Average Spacing', readonly: true },
    { key: 'averageSubdrill', type: 'number', label: 'Average Subdrill', readonly: true },
    { key: 'shotVolume', type: 'number', label: 'Total Volume' },
    { key: 'trucks', type: 'string', label: 'Trucks',readonly: true },
    { key: 'powderFactorVolume', type: 'number', label: 'Powder Factor Volume' },
    { key: 'powderFactorWeight', type: 'number', label: 'Powder Factor Weight' },
    { key: 'tonnage', type: 'number', label: 'Tonnage' },
    { key: 'maxInstantaneousDetonatorCount', type: 'number', label: 'Max No. Of Charges Per 8ms' },
    { key: 'totalNumberOfDetonators', type: 'number', label: 'Total No. Of Detonators', readonly: true },
    { key: 'totalHoles', type: 'number', label: 'Total Designed Holes', readonly: true },
    { key: 'totalDrilledHoles', type: 'number', label: 'Total Drilled Holes', readonly: true },
    { key: 'totalDrilledLength', type: 'number', label: 'Total Drilled Length', readonly: true },
    { key: 'totalDrillingTime', type: 'number', label: 'Total Drilling Time', readonly: true },
    { key: 'averageTimeBetweenHoles', type: 'number', label: 'Avg Time Between Holes', readonly: true },
    { key: 'averageTimePerHole', type: 'number', label: 'Avg Time Per Hole', readonly: true },
    { key: 'utilization', type: 'number', label: 'Utilization', readonly: true }
]

export interface DrillShiftDetails {
    id?: number;
    domainId: number;
    subdomainId: number;
    siteId: number;
    apiV2DatasetId: number;
    datasetName: string;
    travelTimeToAndFromSite: number;
    totalMaintenanceTime: number;
    drillingAndTrammingHours: number;
    equipmentDowntime: number;
    siteStandby: number;
    totalManHours: number;
    totalDailyFootage: number;
    feetPerHour: number;
    bitsConsumed: number;
    steelConsumed: number;
    fuelConsumed: number;
    patternLaidOutBy: string;
    userId: number;
    comment: string;
    recordedAt: string;
}

export const drillShiftDetailsSourceCollectionSchema: DataSourceSchema[] = [
    // { key: 'datasetName', type: 'text', label: 'Project', readonly: true },
    { key: 'travelTimeToAndFromSite', type: 'number', label: 'Travel Time To And From Site' },
    { key: 'drillingAndTrammingHours', type: 'number', label: 'Drilling And Tramming Hours' },
    { key: 'equipmentDowntime', type: 'number', label: 'Equipment Downtime' },
    { key: 'siteStandby', type: 'number', label: 'Site Standby' },
    { key: 'totalManHours', type: 'number', label: 'Total Man Hours', readonly:true},
    { key: 'totalDailyFootage', type: 'number', label: 'Total Daily Footage', readonly:true },
    { key: 'feetPerHour', type: 'number', label: 'Feet Per Hour', readonly:true },
    { key: 'totalMaintenanceTime', type: 'number', label: 'Total Maintenance Time' },
    { key: 'bitsConsumed', type: 'number', label: 'Bits Consumed'},
    { key: 'steelConsumed', type: 'number', label: 'Steel Consumed' },
    { key: 'fuelConsumed', type: 'number', label: 'Fuel Consumed' },
    { key: 'patternLaidOutBy', type: 'text', label: 'Pattern Laid Out By' },
    { key: 'comment', type: 'text', label: 'Comment' },
    { key: 'recordedAt', type: 'date', label: 'Recorded At' }
]


export const mockDataCollection = [{
    apiV2DatasetId: 45,
    averageBenchHeight: 26.98,
    averageHoleDiameter: 0.14,
    averageHoleLength: 28.42,
    averagePatternBurden: 4.18,
    averageSpacing: 4.88,
    averageSubdrill: 1.44,
    averageTimeBetweenHoles: 0,
    averageTimePerHole: null,
    blast: "West Wall",
    datasetName:"test",
    blasterName: "Cole",
    totalBoosters:100,
    totalLabors: 4,
    totalHoursWorked:20,
    trucks:[],
    createdAt: "2024-04-08T11:43:53.998Z",
    dateTime: "2024-03-06T00:00:00.000Z",
    domainId: 4,
    holeCount: 60344,
    id: 5,
    isRecordExist: false,
    locationOfBlast: "Columbus Limestone",
    maxInstantaneousDetonatorCount: 2,
    powderFactorVolume: 0.86,
    powderFactorWeight: 0,
    shotNumber: "07-2024",
    shotVolume: 0,
    tonnage: 31554615.85,
    totalDrillDepth: 34433,
    totalDrillLength: 636,
    totalDrilledHoles: 0,
    totalDrilledLength: 0,
    totalDrillingTime: 0,
    totalHoles: 0,
    totalNumberOfDetonators: 2,
    updatedAt: "2024-04-08T13:17:01.554Z",
    utilization: 0
}]