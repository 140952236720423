import { Component, Input, OnInit } from '@angular/core';
import { SiteService } from '@app/app-state/site.service';
import { FormControl } from '@angular/forms';
import { ChartDataService } from '@app/backend/chart-data.service';
import { FielderDataset } from '@app/models/chart-data.model';

@Component({
    selector: 'app-fielder-dashboard',
    templateUrl: './fielder-dashboard.component.html',
    styleUrls: ['./fielder-dashboard.component.scss']
})
export class FielderDashboardComponent implements OnInit {
    @Input() siteId:number;
    @Input() domainId:number;

    public drillingDataOptions: any;
    public selectedDrillingId: number;
    // public drillingControl = new FormControl<string || FielderDataset>(null);
    public drillingControl =  new FormControl<FielderDataset>(null);

    public subdomainId:number;

    constructor(
        private readonly siteService: SiteService,
        private readonly chartDataService: ChartDataService) {
    }

    ngOnInit(): void {
        this.siteService.subDomainId$.subscribe(subdomainId => {
            this.subdomainId = subdomainId;
            if(this.subdomainId ===  subdomainId) {
                 this.drillingControl.setValue(null);
                 this.siteService.selectedDrillMachine$.next(null)
            }
        });

        if(this.subdomainId) {
            this.chartDataService.getDrillMachines(this.subdomainId);
        }
        
        this.drillingControl.valueChanges.subscribe(value => {
            if(value?.uuid || !value) {
                this.siteService.selectedDrillMachine$.next(value?.uuid);
            } else if(typeof(value) == 'string' && value == '') {
                this.siteService.selectedDrillMachine$.next(null);
            }
        });
        
        this.chartDataService.allFielderDataset$.subscribe((drillingData) => {
            this.drillingDataOptions = drillingData;
            if(drillingData == null) {
                this.drillingControl.setValue(null);
            }
        });
    }

    tabChange(event) {
        this.siteService.activeDrillTab$.next(event)
    }

    displayDrillingName(drillingObj: FielderDataset): string {
        return drillingObj && drillingObj.name ? drillingObj.name : '';
    }

    onDrillingInput(event): void {
        console.log(event);
    }

    onDrillingSelected(event: any): void {
        const selectedDrillingValue = event.option.value as FielderDataset;
        this.drillingControl.setValue(selectedDrillingValue);
    }

    onDrillingSelect(): void {
        const drillingValue = this.drillingControl.value as FielderDataset;
        this.selectedDrillingId = drillingValue?.id;
    }

    resetDrilling(): void {
        this.drillingControl.setValue(null);
        this.onDrillingSelect();
    }
}
