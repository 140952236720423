import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { UserService } from '@app/app-state/user.service';
import { updatedTypeMeta } from '@app/dashboard-measurement/types';
import { ChartDataInterface, DataItem, Series } from '@app/models/chart-data.model';
import { measurement } from '@lib/measurement';
import { Unit } from '@lib/measurement/units';
import { BehaviorSubject } from 'rxjs';
import { SiteService } from '../app-state/site.service';
import { DrillAndBlastDataStructure, FielderAnalysisDataStructure, FragmentationStructure } from './data-structures';

@Injectable({
	providedIn: 'root'
})


export class GenerateChartDataService {
        
	public drillAndBlastDataStructure = DrillAndBlastDataStructure;
	public fielderAnalysisDataStructure = FielderAnalysisDataStructure;
	public fragmentationStructure = FragmentationStructure;

	public originalDrillAndBalstData: ChartDataInterface[] =[];
	public originalFielderAnalytics:ChartDataInterface[] = [];
	public originalFragmentationData:ChartDataInterface[] = [];
	public originalFielderAnalyticsByDrill:ChartDataInterface[]= [];
	public filteredData: ChartDataInterface[] = [];

	private siteId :number;
	private userUnitMeasurement:string;
	
	private chartOptionsSubject = new BehaviorSubject<any>(null);
	chartOptions$ = this.chartOptionsSubject.asObservable();
	
	constructor(
		private siteService:SiteService, 
		private datePipe: DatePipe,
		private userService: UserService) {
		this.siteService.siteId$.subscribe(siteId => this.siteId = siteId);   
		this.userService.userUnitMeasurement$.subscribe(
			measurementUnit => this.userUnitMeasurement = measurementUnit
		)
	}

	public getFilteredData(originalData: ChartDataInterface[], domainId: number, subdomainId: number, siteId: number, startDate:string, endDate: string, selectedTimeline: string) {

		if (!originalData || originalData.length === 0) {
			return [];
		}
		
		if (siteId && !startDate && !endDate && !selectedTimeline ) {
			return originalData.filter(data => data.siteId === siteId);
		} else if (subdomainId && !startDate && !endDate && !selectedTimeline ) {
			return originalData.filter(data => data.subdomainId === subdomainId);
		} else if (domainId && !startDate && !endDate && !selectedTimeline ) {
			return originalData.filter(data => data.domainId === domainId);
		} else if ((domainId || siteId || subdomainId) && startDate && endDate)  {	
			return originalData.filter(data => {
				let formattedDate = this.datePipe.transform(data.datasetCreatedAt, 'yyyy-MM-dd', 'UTC');
				return formattedDate >= startDate  && formattedDate <= endDate;
			});
		} else if((domainId  || siteId || subdomainId ) && startDate) {
			return originalData.filter(data => {
				let formattedDate = this.datePipe.transform(data.datasetCreatedAt, 'yyyy-MM-dd', 'UTC');
				return formattedDate >= startDate;
			})
		} else if((domainId  || siteId || subdomainId) && selectedTimeline) {
	   		const data =  this.processDateAndTimeline(originalData, selectedTimeline);
			return data
		}  
		 else {
			return originalData;
		}
	}

	private getDataStructure() {
		switch (this.siteService.activeSideNav) {
			case 'drillAndBlast':
				return this.drillAndBlastDataStructure;
			
			case 'fielder':
				return this.fielderAnalysisDataStructure;
			
			case 'fragmentation': 
				return this.fragmentationStructure;

			default:
				return null;
		}   
	}    

	public generateChartData(response) {
		const dataStructure = this.getDataStructure();
		const chartOptionsObject = {};
	  
		if (!dataStructure) {
		  return chartOptionsObject;
		}
	  
		Object.keys(dataStructure).forEach(sectionKey => {
		  chartOptionsObject[sectionKey] = {};
	  
		  const sectionData = dataStructure[sectionKey];
	  
		  Object.keys(sectionData).forEach(graphKey => {
			chartOptionsObject[sectionKey][graphKey] = [];
	  
			const graphConfigurations = sectionData[graphKey];
			graphConfigurations.forEach(config => {
			  const { dataField, chartType, chartTitle, yAxisLabel, xAxisLabel, dataFieldTwo, seriesType, seriesType1, isCombineType, isLabourGraph, IsSizeRange, isTruckGraph, isDrillName, isDesignBurdenGraph, valueUnit} = config;
			  let metricUnit:Unit = updatedTypeMeta[valueUnit].metricUnit;
			  let imperialUnit:Unit = updatedTypeMeta[valueUnit].imperialUnit;
                
			  if (response) {
				const chartOptions = this.getChartOptions(dataField, chartType, chartTitle, response, yAxisLabel, xAxisLabel, imperialUnit,metricUnit, this.userService.user.unit(valueUnit),dataFieldTwo, seriesType, seriesType1, isDesignBurdenGraph,isCombineType, isLabourGraph, IsSizeRange, isTruckGraph, isDrillName);
				chartOptionsObject[sectionKey][graphKey].push(chartOptions);
				
				const graphKeyConditions = {
				  'holeCountGraph': 4,
				  'pfTonGraph': 5
				};

				if (graphKeyConditions.hasOwnProperty(graphKey) && chartOptionsObject[sectionKey][graphKey].length === graphKeyConditions[graphKey] && !this.siteId) {
				  chartOptionsObject[sectionKey][graphKey].splice(-1, 1);
				}

				if (this.siteService.activeSideNav === 'drillAndBlast' && graphKey === 'burdenSpacinGraph') {
					const burdenGraphOption = [];
					burdenGraphOption.push(chartOptions);
					this.chartOptionsSubject.next(burdenGraphOption);
				}
				
			  }
			});
		  });
		  
		});
	  
		return chartOptionsObject;
	}
	  
	private getChartOptions(label1: string, chartType: string | null, chartTitle: string, response: Array<any>, yAxisLabel: string, xAxisLabel: string, imperialUnit: Unit, metricUnit: Unit, unit: measurement.Unit, label2?: string | null, seriesType?: string, seriesType1?: string | null, isDesignBurdenGraph?: boolean, isCombineType?: boolean | false, isLabourGraph?: boolean, isSizeRange?: boolean, isTruckGraph?: boolean, isDrillName?: boolean, isExplosiveGraph?: boolean) {
		let datasetName:string[];
		
		const unitAbbreviation = measurement.abbreviation(unit);
		let toUnit:Unit = this.userUnitMeasurement === 'imperial' ? imperialUnit : metricUnit;
		const processCost = (cost: number, label: string) => {
			const convertedValue = measurement.convert(Number(cost[label]), toUnit) 	
			return Math.round(convertedValue * 100) / 100;
		};
		
		const original = response?.map(cost => processCost(cost, label1));
		const combineTypeSeries = response?.map(cost => processCost(cost, label2));
		

		if(isDrillName) {
			datasetName = response?.map(cost => cost.drillMachineName);
		} else {
			datasetName = response?.map(cost => cost.datasetName);
		}

		let seriesData :Series[] = [];

		switch (true) {
			case this.siteId && isTruckGraph:
				const truckGraph = this.generateTruckGraphSeriesData(response);
				let data = Object.values(truckGraph).flat().map(res => Number(res["explosiveAmount"]));
				datasetName = Object.keys(truckGraph);
				data = data.map(cost => {
					const converted = measurement.convert(cost, toUnit);
					return Math.round(converted * 100) / 100;
				});
				seriesData = [
					{ name: yAxisLabel, data },
				];
				break;
			
			case this.siteId && isExplosiveGraph:
				seriesData = [
					{ name: yAxisLabel, data: original },
				];
				break;

			case isSizeRange:
				seriesData = this.generateSizeRangeSeriesData(response);
				break;
		
			case isCombineType && isLabourGraph:
				const uniqueSites = {};
				response.forEach(item => {
					if (!(item.siteId in uniqueSites)) {
						uniqueSites[item.siteId] = item.siteName;
					}
				});
				datasetName = Object.values(uniqueSites);
				seriesData = this.generateLabourAndHourSeriesData(response);
				break;

			case isCombineType:
				seriesData = [
					{ name: label1.replace(/([a-z])([A-Z])/g, '$1 $2'), data: original, type: seriesType },
					{ name: label2.replace(/([a-z])([A-Z])/g, '$1 $2'), data: combineTypeSeries, type: seriesType1 },
				];
				break;

			default:
				seriesData = [
					{ name: yAxisLabel, data: original },
				];
				break;
		}

		const chartOptionsData =  this.chartOptions(chartType, datasetName, chartTitle, seriesData, yAxisLabel, xAxisLabel, unitAbbreviation, isSizeRange);
		
		if(isSizeRange) {
			chartOptionsData.chart['isFragmentSizeRangeChart'] = true;
			const { unit, conversionFactor } = this.calculateUnitAndConversionFactor(this.userUnitMeasurement);

			if(this.siteId) {
				chartOptionsData['goalSeriesData'] = {
					color: "#F535AA",
					name: `Fines < ${(10 * conversionFactor).toFixed(2)}${unit}`,
					data: response.map(data => data.finesGoal),
					datasetIds: response.map(data => data.datasetId)
				}
			}
			else {
				chartOptionsData['goalSeriesData'] = {
					color: "#F535AA",
					name: `Fines < ${(10 * conversionFactor).toFixed(2)}${unit}`,
					data: [],
					datasetIds: []
				}
			}
		}

		if(isCombineType && isDesignBurdenGraph) {
			chartOptionsData.chart['isDesignBurdenGraph'] = true;
			chartOptionsData['datasetId'] = response.map(data => data.datasetId);
			chartOptionsData['designBurdens'] = response.map(data => data.designBurden);
		}

		return chartOptionsData;
	}

	private generateSizeRangeSeriesData(response) {
		const values = Array.from({ length: 8 }, () => []);
		const { unit, conversionFactor } = this.calculateUnitAndConversionFactor(this.userUnitMeasurement);

		let rangeData: DataItem[];

		let resultsWithFragmentation = response.filter(res => res.fragmentationSizeRanges && Object.keys(res.fragmentationSizeRanges).length > 0);
	
		resultsWithFragmentation.forEach(result => {
			Object.values(result.fragmentationSizeRanges).forEach((value, index) => {
				if (value) {
					values[index].push(value);
				}
			});
		});
	
		if (values[5].length > 0 && values[6].length > 0 && values[7].length > 0) {
			rangeData = [
				{ color: '#800000', name: `>${(2540 * conversionFactor).toFixed(2)}${unit}`, data: values[7].map(e => Number(e.toFixed(2))) },
				{ color: '#808080', name: `${(2032 * conversionFactor).toFixed(2)}${unit} to ${(2540 * conversionFactor).toFixed(2)}${unit}`, data: values[6].map(e => Number(e.toFixed(2))) },
				{ color: '#00FFFF', name: `${(1016 * conversionFactor).toFixed(2)}${unit} to ${(2032 * conversionFactor).toFixed(2)}${unit}`, data: values[5].map(e => Number(e.toFixed(2))) },
				{ color: '#008000', name: `${(508 * conversionFactor).toFixed(2)}${unit} to ${(1016 * conversionFactor).toFixed(2)}${unit}`, data: values[4].map(e => Number(e.toFixed(2))) },
				{ color: '#0000FF', name: `${(254 * conversionFactor).toFixed(2)}${unit} to ${(508 * conversionFactor).toFixed(2)}${unit}`, data: values[3].map(e => Number(e.toFixed(2))) },
				{ color: '#FFFF00', name: `${(51 * conversionFactor).toFixed(2)}${unit} to ${(254 * conversionFactor).toFixed(2)}${unit}`, data: values[2].map(e => Number(e.toFixed(2))) },
				{ color: '#FF0000', name: `${(10 * conversionFactor).toFixed(2)}${unit} to ${(51 * conversionFactor).toFixed(2)}${unit}`, data: values[1].map(e => Number(e.toFixed(2))) },
				{ color: "#F535AA", name: `Fines < ${(10 * conversionFactor).toFixed(2)}${unit}`, data: values[0].map(e => Number(e.toFixed(2))) }
			];
		} else {
			rangeData = [
				{ color: '#FF0000', name: `>${(2500 * conversionFactor).toFixed(2)}${unit}`, data: values[4].map(e => Number(e.toFixed(2))) },
				{ color: '#FF7F00', name: `${(1500 * conversionFactor).toFixed(2)}${unit} to ${(2500 * conversionFactor).toFixed(2)}${unit}`, data: values[3].map(e => Number(e.toFixed(2))) },
				{ color: '#00FF00', name: `${(500 * conversionFactor).toFixed(2)}${unit} to ${(1500 * conversionFactor).toFixed(2)}${unit}`, data: values[2].map(e => Number(e.toFixed(2))) },
				{ color: '#0000FF', name: `${(10 * conversionFactor).toFixed(2)}${unit} to ${(500 * conversionFactor).toFixed(2)}${unit}`, data: values[1].map(e => Number(e.toFixed(2))) },
				{ color: "#F535AA", name: `Fines < ${(10 * conversionFactor).toFixed(2)}${unit}`, data: values[0].map(e => Number(e.toFixed(2))) }
			];
		}
	
		return rangeData;
	}
	
	
	private generateLabourAndHourSeriesData(response)  {
		const averagesBySite = response.reduce((acc, item) => {
			const siteId = item.siteId;
			acc[siteId] = acc[siteId] || { laborSum: 0, hoursSum: 0, count: 0 };
			acc[siteId].laborSum += item.noOfLabors;
			acc[siteId].hoursSum += item.hours;
			acc[siteId].count++;
			return acc;
	   }, {});

		const dataArray: { laborSum: number,hoursSum:number, count: number }[] = Object.values(averagesBySite);

		const noOfLabors = dataArray.map(siteData => parseInt((siteData.laborSum / siteData.count).toFixed(2)));
		const noOfHours = dataArray.map(siteData => parseInt((siteData.hoursSum / siteData.count).toFixed(2)));

		const seriesData = [
			{ name: "Average laborers", data: noOfLabors, type: 'column' },
			{ name: "Average hours", data: noOfHours, type: 'column' },
		];
		return seriesData;
	}

	private generateTruckGraphSeriesData(response) {
		let truckData = response
			.map(res => res.trucks)
			.filter(Boolean)
			.flat()
			.reduce((acc, res) => {
				if (!acc[res.code]) {
					acc[res.code] = [];
				}
				acc[res.code].push(res);
				return acc;
		}, {});

		return  this.mergeExplosiveAmount(truckData)
	}
	
	private mergeExplosiveAmount(truckData) {
		const mergedData = {};        
		Object.keys(truckData).forEach(code => {
			const mergedObject = truckData[code].reduce((acc, obj) => {
				if (acc.code === undefined) {
					acc = { ...obj };
				} else {
					acc.explosiveAmount = (parseInt(acc.explosiveAmount) + parseInt(obj.explosiveAmount)).toString();
				}
				return acc;
			}, {});
			mergedData[code] = [mergedObject];
		});
		
		return mergedData;
	}
	
	private chartOptions(chartType: string, xAxisCategories: string[], chartTitle: string, seriesData: any, yAxisLabel: string, xAxisLabel:string, unitAbbreviation, isSizeRange?) {
		
		const options:Highcharts.Options = {
			chart: {
				type: chartType,
				style: {
					fontFamily: 'Lato'
				}			},
			title: {
				text: `${chartTitle}`,
				align: 'left'
			},
			xAxis: {
				categories: xAxisCategories,
				title: {
					text: xAxisLabel,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			yAxis: {
				title: {
					text: unitAbbreviation ? `${yAxisLabel} (${unitAbbreviation})` : yAxisLabel,
					style: {
						fontSize: '1rem'
					}
				},
				labels: {
					style: {
						fontSize: '1rem'
					}
				}
			},
			credits: {
				enabled: false
			},
			series: seriesData
		}
		return options;
  	}

	private processDateAndTimeline(originalData, timeline) {
		const timelineRanges = { '1D': 1, '1W': 7, '1M': 30, '3M': 90, '6M': 180, '1Y': 365 };
		if (timelineRanges[timeline]) {
			const daysAgo = timelineRanges[timeline];
			const endDate = new Date();
			const startDate = new Date();
			startDate.setDate(startDate.getDate() - daysAgo);
			return originalData.filter(data => {
				const createdAt = new Date(data.datasetCreatedAt);
				return createdAt >= startDate && createdAt <= endDate;
			});
		}

		return originalData;
	}
	
	private calculateUnitAndConversionFactor(userUnitMeasurement) {
		const unit = userUnitMeasurement === "imperial" ? 'in' : 'mm';
		const conversionFactor = unit === 'in' ? 0.0393701 : 1;
		return { unit, conversionFactor };
	}
	
}