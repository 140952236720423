
export type Unit=
'none'

//distance
|'millimeter'
|'centimeter'
|'inch'
|'decameter'
|'feet'
|'usfeet'
|'yard'
|'meter'
|'kilometer'
|'mile'

//area
|'feet2'
|'yard2'
|'meter2'
|'acre'
|'kilometer2'

//volume
|'feet3'
|'yard3'
|'meter3'

//mass-weight
|'gram'
|'kilogram'
|'pounds'
|'metricton'
|'shortton'

//density
|'poundsPerYard3'
|'kilogramPerMeter3'
|'gramPerCentimeter3'
|'metrictonPerMeter3'
|'shorttonPerYard3'

//pressure
|'pascal'
|'kilopascal'
|'poundsPerInch2'
|'bar'
|'megapascal'

//energy density
|'kilocaloriePerPound'	// Kcal per pound
|'megaJoulePerKilogram'	// Mega Joule per kilogram

//angle
|'degrees'
|'radians'

//time
|'millisecond'
|'second'
|'hour'
|'minute'

//percent
|'percent'

//velocity
|'millimeterPerSecond'
|'inchPerSecond'
|'feetPerSecond'
|'meterPerSecond'
|'kilometerPerHour'
|'milePerHour'

//time per distance (used by burden relief)
|'secondPerMeter'
|'secondPerFeet'
|'millisecondPerMeter'
|'millisecondPerFeet'

//sound
|'deciBel'
|'megaDeciBel'

//weight per length
|'kilogramPerMeter'
|'poundsPerYard'

//miscellaneous
|'meterPerSquareRootKg'
|'meterPerCubeRootKg'
|'feetPerSquareRootPound'
|'feetPerCubeRootPound'

//temperature
|'kelvin'
|'celsius'
|'fahrenheit'

//frequency
|'hertz'

//misc
|'lonLatDegree'

//distance per pixel (used by ground sample distance)
|'meterPerPixel'
|'milliMeterPerPixel'
|'inchPerPixel'

//weight by large weight ratio
|'kilogramPerMetricton'
|'poundsPerShortton'
|'kilogramPerKilogram';

interface UnitMeta{
	unit?:Unit;
	inBase:number;
	offsetFromBase:number;
	abbreviation:string;
	fullName?:string;
	decimalCount:number;
}

export const unitMeta:{[unit in Unit]:UnitMeta}={
	none: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "",
		fullName: "",
		decimalCount: 2
	},

	//---distance---
	millimeter: {
		inBase: 0.001,
		offsetFromBase: 0,
		abbreviation: "mm",
		decimalCount: 0
	},
	centimeter: {
		inBase: 0.01,
		offsetFromBase: 0,
		abbreviation: "cm",
		decimalCount: 2
	},
	inch: {
		inBase: 0.3048/12,
		offsetFromBase: 0,
		abbreviation: "in",
		decimalCount: 2,
	},
	decameter: {
		inBase: 0.1,
		offsetFromBase: 0,
		abbreviation: "dm",
		decimalCount: 2
	},
	feet: {
		inBase: 0.3048,
		offsetFromBase: 0,
		abbreviation: "ft",
		fullName: "feet",
		decimalCount: 2
	},
	usfeet: {
		inBase: 1200/3937,
		offsetFromBase: 0,
		abbreviation: "us-ft",
		decimalCount: 2
	},
	yard: {
		inBase: 0.3048*3,
		offsetFromBase: 0,
		abbreviation: "yd",
		fullName: "yard",
		decimalCount: 2
	},
	meter: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "m",
		fullName: "meter",
		decimalCount: 2
	},
	kilometer: {
		inBase: 1000,
		offsetFromBase: 0,
		abbreviation: "km",
		decimalCount: 2
	},
	mile: {
		inBase: 1609.34,
		offsetFromBase: 0,
		abbreviation: "mi",
		decimalCount: 2
	},

	//---area---
	feet2: {
		inBase: 0.3048**2,
		offsetFromBase: 0,
		abbreviation: "ft²",
		fullName:"sqaure feet",
		decimalCount: 2
	},
	yard2: {
		inBase: (0.3048*3)**2,
		offsetFromBase: 0,
		abbreviation: "yd²",
		fullName: "square yard",
		decimalCount: 2
	},
	meter2: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "m²",
		fullName: "square meter",
		decimalCount: 2
	},
	acre: {
		inBase: 4046.86,
		offsetFromBase: 0,
		abbreviation: "acre",
		fullName: "acre",
		decimalCount: 2
	},
	kilometer2: {
		inBase: 1000000,
		offsetFromBase: 0,
		abbreviation: "km²",
		fullName: "sqaure kilometer",
		decimalCount: 2
	},

	//---volume---
	feet3: {
		inBase: 0.3048**3,
		offsetFromBase: 0,
		abbreviation: "ft³",
		fullName: "cubic feet",
		decimalCount: 2
	},
	yard3: {
		inBase: (0.3048*3)**3,
		offsetFromBase: 0,
		abbreviation: "yd³",
		fullName: "cubic yard",
		decimalCount: 2
	},
	meter3: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "m³",
		fullName: "cubic meter",
		decimalCount: 2
	},

	//---mass-weight---
	gram: {
		inBase: 0.001,
		offsetFromBase: 0,
		abbreviation: "g",
		decimalCount: 2
	},
	kilogram: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "kg",
		decimalCount: 2
	},
	pounds: {
		inBase: 0.453592,
		offsetFromBase: 0,
		abbreviation: "lb",
		decimalCount: 2
	},
	metricton: {
		inBase: 1000,
		offsetFromBase: 0,
		abbreviation: "mt",
		fullName: "metric ton",
		decimalCount: 4
	},
	shortton: {
		inBase: 907.185,
		offsetFromBase: 0,
		abbreviation: "tn",
		fullName: "short ton",
		decimalCount: 4
	},

	//---density---
	poundsPerYard3: {
		inBase: 0.593276,
		offsetFromBase: 0,
		abbreviation: "lb/yd³",
		decimalCount: 2
	},
	kilogramPerMeter3: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "kg/m³",
		decimalCount: 2
	},
	gramPerCentimeter3: {
		inBase: 0.001/(0.01**3),
		offsetFromBase: 0,
		abbreviation: "g/cc",
		decimalCount: 2
	},
	metrictonPerMeter3: {
		inBase: 1000,
		offsetFromBase: 0,
		abbreviation: "mt/m³",
		decimalCount: 4
	},
	shorttonPerYard3: {
		inBase: 907.185/((0.3048*3)**3),
		offsetFromBase: 0,
		abbreviation: "tn/yd³",
		decimalCount: 4
	},

	//---pressure---
	pascal: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "Pa",
		decimalCount: 2
	},
	kilopascal: {
		inBase: 1000,
		offsetFromBase: 0,
		abbreviation: "kPa",
		decimalCount: 2
	},
	poundsPerInch2: {
		inBase: 6894.76,
		offsetFromBase: 0,
		abbreviation: "psi",
		decimalCount: 2
	},
	bar: {
		inBase: 100000,
		offsetFromBase: 0,
		abbreviation: "bar",
		decimalCount: 2
	},
	megapascal: {
		inBase: 1000000,
		offsetFromBase: 0,
		abbreviation: "MPa",
		decimalCount: 2
	},

	//---energy density---
	kilocaloriePerPound: {
		inBase: 0.00922414,
		offsetFromBase: 0,
		abbreviation: "kcal/lb",
		decimalCount: 2
	},

	megaJoulePerKilogram: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "MJ/kg",
		decimalCount: 2
	},

	//---angle---
	degrees: {
		inBase: Math.PI/180,
		offsetFromBase: 0,
		abbreviation: "°",
		decimalCount: 1
	},
	radians: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "rad",
		decimalCount: 1
	},

	//---time---
	millisecond: {
		inBase: 0.001,
		offsetFromBase: 0,
		abbreviation: "ms",
		decimalCount: 2
	},
	second: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "s",
		decimalCount: 2
	},
	percent: {
		inBase: 0.01,
		offsetFromBase: 0,
		abbreviation: "%",
		decimalCount: 2
	},
	hour: {
		inBase: 3600,
		offsetFromBase: 0,
		abbreviation: "hr",
		decimalCount: 2
	},
	minute: {
		inBase: 60,
		offsetFromBase: 0,
		abbreviation: "min",
		decimalCount: 2
	},

	//---velocity
	millimeterPerSecond: {
		inBase: 0.001,
		offsetFromBase: 0,
		abbreviation: "mm/s",
		decimalCount: 2
	},
	inchPerSecond: {
		inBase: 0.3048/12,
		offsetFromBase: 0,
		abbreviation: "in/s",
		decimalCount: 4,
	},
	feetPerSecond: {
		inBase: 0.3048,
		offsetFromBase: 0,
		abbreviation: "ft/s",
		decimalCount: 2
	},
	meterPerSecond: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "m/s",
		decimalCount: 2
	},
	kilometerPerHour: {
		inBase: 1000/3600,
		offsetFromBase: 0,
		abbreviation: "kmph",
		decimalCount: 2
	},
	milePerHour: {
		inBase: 1609.34/3600,
		offsetFromBase: 0,
		abbreviation: "mph",
		decimalCount: 2
	},

	//---time per distance---
	secondPerMeter: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "s/m",
		decimalCount: 2
	},
	secondPerFeet: {
		inBase: 1/0.3048,
		offsetFromBase: 0,
		abbreviation: "s/ft",
		decimalCount: 2
	},
	millisecondPerMeter: {
		inBase: 0.001,
		offsetFromBase: 0,
		abbreviation: "ms/m",
		decimalCount: 2
	},
	millisecondPerFeet: {
		inBase: 0.001/0.3048,
		offsetFromBase: 0,
		abbreviation: "ms/ft",
		decimalCount: 2
	},

	//---sound---
	deciBel: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "dB",
		decimalCount: 2
	},
	megaDeciBel: {
		inBase: 1000000,
		offsetFromBase: 0,
		abbreviation: "MdB",
		decimalCount: 2
	},

	//---weight per length---
	kilogramPerMeter: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "kg/m",
		decimalCount: 2
	},
	poundsPerYard: {
		inBase: 0.496055,
		offsetFromBase: 0,
		abbreviation: "lb/yd",
		decimalCount: 2
	},

	//---miscellaneous---
	meterPerSquareRootKg: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "m/√kg",
		decimalCount: 2
	},
	meterPerCubeRootKg: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "m/∛kg",
		decimalCount: 2
	},
	feetPerSquareRootPound: {
		inBase: 0.3048/(0.453592**(1/2)),
		offsetFromBase: 0,
		abbreviation: "ft/√lb",
		decimalCount: 2
	},
	feetPerCubeRootPound: {
		inBase: 0.3048/(0.453592**(1/3)),
		offsetFromBase: 0,
		abbreviation: "ft/∛lb",
		decimalCount: 2
	},

	//---temperature---
	kelvin: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "°",
		decimalCount: 2
	},
	celsius: {
		inBase: 1,
		offsetFromBase: -273.15,
		abbreviation: "°",
		decimalCount: 2
	},
	fahrenheit: {
		inBase: 0.5555555555555556,
		offsetFromBase: -459.66999999999996,
		abbreviation: "°",
		decimalCount: 2
	},

	//---frequency---
	hertz: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "Hz",
		decimalCount: 2
	},

	//---misc---
	lonLatDegree: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "°",
		decimalCount: 9,
	},

	// 
	meterPerPixel: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "m/pixel",
		fullName: "meter/pixel",
		decimalCount: 2
	},
	milliMeterPerPixel: {
		inBase: 0.001,
		offsetFromBase: 0,
		abbreviation: "mm/pixel",
		decimalCount: 2
	},	
	inchPerPixel: {
		inBase: 0.3048/12,
		offsetFromBase: 0,
		abbreviation: "in/pixel",
		decimalCount: 4,
	},

	//---weight by large weight ratio---
	kilogramPerMetricton: {
		inBase: 1000,
		offsetFromBase: 0,
		abbreviation: "kg/ton",
		decimalCount: 2
	},
	poundsPerShortton: {
		inBase: 2000,
		offsetFromBase: 0,
		abbreviation: "lb/tn",
		decimalCount: 2
	},
	kilogramPerKilogram: {
		inBase: 1,
		offsetFromBase: 0,
		abbreviation: "kg/kg",
		decimalCount: 2
	}
};

for(const key in unitMeta){
	const meta=unitMeta[<Unit>key];
	meta.unit=<Unit>key;
}
