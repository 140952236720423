import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import * as backend from '@app/backend';
import { DataSourceSchema, DrillAndBlastCostsParams, dataSourceCollectionSchema } from '@app/models/drill-and-blast-costs.model';
import * as Highcharts from 'highcharts/highstock';
import { combineLatest, debounceTime } from 'rxjs';
import { DrillAndBlastCostService } from './service';

@Component({
	selector: 'app-drill-and-blast-costs',
	templateUrl: './drill-and-blast-costs.component.html',
	styleUrls: ['./drill-and-blast-costs.component.scss']
})
export class DrillAndBlastCostsComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;

	public selectedMatTabIndex: number = 0;
	public loadingDrillAndBlastCosts: boolean;

	public currentDrillAndBlastCost: DrillAndBlastCostsParams;
	public drillAndBlastCosts: DrillAndBlastCostsParams[] = [];
	public dataSourceSchema: DataSourceSchema[] = dataSourceCollectionSchema;
	public displayedColumns: string[] = this.dataSourceSchema.map(col => col.key);
	public dataSource: MatTableDataSource<DrillAndBlastCostsParams>;

	private tonsPerHourGraph:Highcharts.Chart;
	@ViewChild('tonsPerHourGraph') tonsPerHourGraphDiv: ElementRef<HTMLDivElement>;
	private finesPercentGraph:Highcharts.Chart;
	@ViewChild('finesPercentGraph') finesPercentGraphDiv: ElementRef<HTMLDivElement>;
	private oversizePercentGraph:Highcharts.Chart;
	@ViewChild('oversizePercentGraph') oversizePercentGraphDiv: ElementRef<HTMLDivElement>;
	private explosivesCostPerTonGraph:Highcharts.Chart;
	@ViewChild('explosivesCostPerTonGraph') explosivesCostPerTonGraphDiv: ElementRef<HTMLDivElement>;
	private drillingCostPerTonGraph:Highcharts.Chart;
	@ViewChild('drillingCostPerTonGraph') drillingCostPerTonGraphDiv: ElementRef<HTMLDivElement>;
	private drillAndBlastCostPerTonGraph:Highcharts.Chart;
	@ViewChild('drillAndBlastCostPerTonGraph') drillAndBlastCostPerTonGraphDiv: ElementRef<HTMLDivElement>;
	private secondaryBreakageCostGraph:Highcharts.Chart;
	@ViewChild('secondaryBreakageCostGraph') secondaryBreakageCostGraphDiv: ElementRef<HTMLDivElement>;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	public siteDatasetLists: {id: number, name: string}[];

	constructor(
		private snackBar: MatSnackBar,
		private drillAndBlastCostService: DrillAndBlastCostService,
		private readonly siteService: SiteService
	) {
		// Combine subDomainId$ and siteId$ streams
		combineLatest([this.siteService.subDomainId$, this.siteService.siteId$])
		.pipe(
			debounceTime(300),
		).subscribe(([subdomainId, siteId]) => {
			this.subdomainId = subdomainId;
			this.siteId = siteId;

			// Filter based on the current subdomainId and siteId
			this.drillAndBlastCosts = this.filterCosts(subdomainId, siteId);
			this.dataSource = new MatTableDataSource(this.drillAndBlastCosts);

			// Ensure paginator is set
			if (!this.dataSource.paginator) {
				this.dataSource.paginator = this.paginator;
			}

			// Update chart and loading status
			this.constructDrillAndBlastCostCharts();
			this.loadingDrillAndBlastCosts = false;
		});
	}

	ngOnInit() {
		// Read only Fields
		this.displayedColumns.unshift('datasetName');

		this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;

			if (domainId) {
				this.currentDrillAndBlastCost = undefined;
				this.constructChartsAndPopulateTableView(domainId);
			}
		});

	}

	ngAfterViewInit() {
		this.constructDrillAndBlastCostCharts();
	}
	
	private async constructChartsAndPopulateTableView(domainId: number) {
		this.loadingDrillAndBlastCosts = true;
		await this.fetchDrillAndBlastCostsByDomain(domainId);
		this.loadingDrillAndBlastCosts = false;
	}

	private async fetchDrillAndBlastCostsByDomain(domainId: number) {
		try {
			const response = await backend.getDrillAndBlastCostsByDomain(domainId);
	
			if (!response || response.length <= 0) {
				this.drillAndBlastCosts = [];
				this.dataSource = new MatTableDataSource([]);
				this.loadingDrillAndBlastCosts = false;
				this.constructDrillAndBlastCostCharts();
				return;
			}
	
			this.drillAndBlastCostService.originalDrillAndBlastCosts = response;
			this.drillAndBlastCosts = response;
			this.constructDrillAndBlastCostCharts();
		} catch (error) {
			console.error('Error fetching drill and blast costs:', error);
			// Handle the error appropriately here
			this.loadingDrillAndBlastCosts = false;
		}
	}
	
	private filterCosts(subdomainId: number | null, siteId: number | null) {
		if (siteId) {
			return this.drillAndBlastCostService.originalDrillAndBlastCosts.filter(data => data.siteId == siteId);
		} else if (subdomainId) {
			return this.drillAndBlastCostService.originalDrillAndBlastCosts.filter(data => data.subdomainId == subdomainId);
		} else {
			return this.drillAndBlastCostService.originalDrillAndBlastCosts.filter(data => data.domainId == this.domainId);
		}
	}

	private constructDrillAndBlastCostCharts() {
		this.drillAndBlastCostService.constructDrillAndBlastCostCharts(this.drillAndBlastCosts);

		this.tonsPerHourGraph?.destroy();
		this.tonsPerHourGraph = Highcharts.chart(this.tonsPerHourGraphDiv.nativeElement, this.drillAndBlastCostService.tonsPerHourChartOptions);

		this.finesPercentGraph?.destroy();
		this.finesPercentGraph = Highcharts.chart(this.finesPercentGraphDiv.nativeElement, this.drillAndBlastCostService.finesPercentChartOptions);

		this.oversizePercentGraph?.destroy();
		this.oversizePercentGraph = Highcharts.chart(this.oversizePercentGraphDiv.nativeElement, this.drillAndBlastCostService.oversizePercentChartOptions);

		this.explosivesCostPerTonGraph?.destroy();
		this.explosivesCostPerTonGraph = Highcharts.chart(this.explosivesCostPerTonGraphDiv.nativeElement, this.drillAndBlastCostService.explosivesCostPerTonChartOptions);

		this.drillingCostPerTonGraph?.destroy();
		this.drillingCostPerTonGraph = Highcharts.chart(this.drillingCostPerTonGraphDiv.nativeElement, this.drillAndBlastCostService.drillingCostPerTonChartOptions);

		this.drillAndBlastCostPerTonGraph?.destroy();
		this.drillAndBlastCostPerTonGraph = Highcharts.chart(this.drillAndBlastCostPerTonGraphDiv.nativeElement, this.drillAndBlastCostService.drillAndBlastCostPerTonChartOptions);

		this.secondaryBreakageCostGraph?.destroy();
		this.secondaryBreakageCostGraph = Highcharts.chart(this.secondaryBreakageCostGraphDiv.nativeElement, this.drillAndBlastCostService.secondaryBreakageCostChartOptions);
	}

	onFieldInputClick(event) {
		const target = event.target;
		target.readOnly = false;
	}

	async onFieldInputChange(element, key: string, value: string|number|Date) {
		if (!element.id) return;

		if (key && value) {
			const drillAndBlastCost = this.drillAndBlastCosts.find(cost => cost.id == element.id);
			if (drillAndBlastCost) {
				drillAndBlastCost[key] = value;

				const response = await backend.updateDrillAndBlastCosts(drillAndBlastCost);
				if (response) {
					this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
				}
			}
		}
	}

	public onSelectedMatTabChange(event) {
		if (event.index == 0) {
			this.constructDrillAndBlastCostCharts();
		}
	}

	public async initiateDrillAndBlastCosts() {
		if (this.currentDrillAndBlastCost) {
			this.snackBar.open('Please fill the current row Drill And Blast Costs', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});

			return;
		}

		if (!this.siteId) {
			this.snackBar.open('Please select Site prior adding Costs', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});

			return;
		}

		if (this.selectedMatTabIndex === 0) {
			this.selectedMatTabIndex = 1; 				// Change into table view
		}
		this.loadingDrillAndBlastCosts = true;

		this.siteDatasetLists = await backend.fetchSiteDatasetsList(this.siteId);

		this.currentDrillAndBlastCost = {
			tonsPerHour: 0,
			tonsPerHourGoal: 0,
			finesPercent: 0,
			finesPercentGoal: 0,
			oversizePercent: 0,
			oversizePercentGoal: 0,
			explosivesCostPerTon: 0,
			explosivesCostPerTonGoal: 0,
			drillingCostPerTon: 0,
			drillingCostPerTonGoal: 0,
			drillAndBlastCostPerTon: 0,
			drillAndBlastCostPerTonGoal: 0,
			secondaryBreakageCost: 0,
			secondaryBreakageCostGoal: 0,
			recordedAt: new Date().toLocaleDateString(),
			datasetName: '',
			apiV2DatasetId: undefined,
			siteId: this.siteId,
			domainId: this.domainId,
			subdomainId: this.subdomainId
		};

		this.drillAndBlastCosts.push(this.currentDrillAndBlastCost);
		this.dataSource = new MatTableDataSource(this.drillAndBlastCosts);
		this.loadingDrillAndBlastCosts = false;
	}

	public onDatasetSelect(event) {
		const value = event.value;

		const isCostFoundPerProject = this.drillAndBlastCosts.find(cost => cost.apiV2DatasetId == value.id);
		if (isCostFoundPerProject) {
			this.snackBar.open('A Project should have only one Cost. Please select other Project and add Cost.', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
			event.source.value = undefined;

			return;
		}

		this.currentDrillAndBlastCost.datasetName = value.name;
		this.currentDrillAndBlastCost.apiV2DatasetId = value.id;
	}

	public async addDrillAndBlastCosts() {
		if (this.currentDrillAndBlastCost && !this.currentDrillAndBlastCost.apiV2DatasetId) {
			this.snackBar.open('Please select a Project and add Cost.', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });

			return;
		}

		const response = await backend.createDrillAndBlastCosts(this.currentDrillAndBlastCost);
		if (response) {
			this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });

			if (response.id) {
				this.currentDrillAndBlastCost.id = response.id;
				this.drillAndBlastCosts = [...this.drillAndBlastCosts];

				this.drillAndBlastCostService.originalDrillAndBlastCosts.push(this.currentDrillAndBlastCost);

				this.currentDrillAndBlastCost = undefined;
			}
		}
	}
}
