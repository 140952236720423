import { Injectable } from '@angular/core';

import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, of, switchMap, take } from 'rxjs';
import { getPromise } from "./request-promise";

import { SiteService } from '../app-state/site.service';

import { ChartDataInterface, FielderDataset } from "@app/models/chart-data.model";
import { GenerateChartDataService } from './generate-chart-data.service';

@Injectable({
	providedIn: 'root'
})

export class ChartDataService {

	readonly drillDetails$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly fragmentationData$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly fielderAnalytics$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly fielderAnalyticsByDrill$ = new BehaviorSubject<ChartDataInterface[]>(null);
	readonly allFielderDataset$ = new BehaviorSubject<FielderDataset[]>(null)
	private loadingSubject = new BehaviorSubject<number>(0);
	public loading$ = this.loadingSubject.asObservable();

	public get allFielderDataset() {
		return this.allFielderDataset$.value;
	};

	private domainId;
	private subdomainId;
	private siteId;
	private selectedDrillMachine;
	private loadingData = false;

	constructor(private siteService: SiteService, private generateChartDataService:GenerateChartDataService){
		combineLatest([
			siteService.domainId$,
		]).pipe(
			debounceTime(300),
			distinctUntilChanged()
		)
		.subscribe(([domainId]) => {
			this.domainId = domainId;
			this.siteId = this.siteId
			if(this.domainId) {
				this.resetAllValues();
		}
		});

		this.siteService.selectedDrillMachine$.subscribe(selectedDrillMachine => {
			if(this.selectedDrillMachine !== selectedDrillMachine && selectedDrillMachine !== undefined) {
				this.selectedDrillMachine = selectedDrillMachine
				this.resetAllValues();
			}
		});
	}

	private resetAllValues() {
		this.drillDetails$.next(null);
		this.fragmentationData$.next(null);
		this.fielderAnalytics$.next(null);
		this.fielderAnalyticsByDrill$.next(null);
		if(this.domainId) {
			this.loadingSubject.next(this.loadingSubject.value + 1);
			this.updateData();
		}
	}

	private updateData() {
		switch(this.siteService.activeSideNav) {
			case 'drillAndBlast':
			this.getAllDrillDetails();
			break;

			case 'fragmentation':
			this.getAllFragmentationData()
			break;

			case 'fielder':
			this.getAllFielderAnalytics()
			this.getAllFielderAnalyticsByDrill()
			break;
		}
	}

	public getAllDrillDetails() {
		return getPromise(
			`api/dashboards/highchart_analytics/drill_details?${this.apiParams()}`,
			(data) => data.data
		).then((details) => {
			this.generateChartDataService.originalDrillAndBalstData = details;
			this.drillDetails$.next(details);
			this.loadingData = false;
			return details
		}).finally(() => this.handleLoading());
	}

	public getAllFragmentationData() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fragmentation_chart_data?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			this.generateChartDataService.originalFragmentationData = details;
			this.fragmentationData$.next(details)
			this.loadingData = false;
			return details
		}).finally(() => this.handleLoading());
	}

	public getAllFielderAnalytics() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fielder_analytics_data?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			this.generateChartDataService.originalFielderAnalytics = details;
			this.fielderAnalytics$.next(details)
			this.loadingData = false;
			return details
		}).finally(() => this.handleLoading());
	}

	public getAllFielderAnalyticsByDrill() {
		return getPromise(
			`/api/dashboards/highchart_analytics/fielder_analytics_drill_data?${this.apiParams()}`,
		(data) => data.data).then((details) => {
			if(!this.selectedDrillMachine) {
				this.generateChartDataService.originalFielderAnalyticsByDrill = details;
			} 
			this.fielderAnalyticsByDrill$.next(details)
			this.loadingData = false;
			this.selectedDrillMachine = null;
			return details
		}).finally(() => this.handleLoading());
	}

	public getDrillMachines(subdomainId) {
		return getPromise(
			`/api/dashboards/highchart_analytics/fetch_drill_machines?subdomain_id=${subdomainId}`,
		(data) => data.data).then((details) => {
			this.allFielderDataset$.next(details)
			this.loadingData = false;
			return details;
		}).finally(() => this.handleLoading());
	}

	public getCacheDrillDetails() {
		return this.drillDetails$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllDrillDetails();
			}
			return of(details);
			}),
		);
	}

	public getCacheFragmentationData() {
		return this.fragmentationData$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllFragmentationData();
			}
			return of(details);
			}),
		);
	}

	public getCacheFielderAnalytics() {
		return this.fielderAnalytics$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllFielderAnalytics();
			}
			return of(details);
			}),
		);
	}

	public getCacheFielderAnalyticsByDrill() {
		return this.fielderAnalyticsByDrill$.pipe(
			take(1),
			switchMap((details) => {
			if (details === null && !this.loadingData) {
				return this.getAllFielderAnalyticsByDrill();
			}
			return of(details);
			}),
		);
	}

	public apiParams(){
		if(this.selectedDrillMachine) {
			return `${this.domainId ? '&domain_id=' + this.domainId : ''}${this.subdomainId ? '&subdomain_id=' + this.subdomainId : ''}${this.selectedDrillMachine ? '&uuid=' + this.selectedDrillMachine : ''}`
		}
		else {
			return `${this.domainId ? '&domain_id=' + this.domainId : ''}`;
		}
	}

	private handleLoading() {
		this.loadingSubject.next(Math.max(0, this.loadingSubject.value - 1));
	}
}


  