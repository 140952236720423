import { Component, OnDestroy, OnInit } from '@angular/core';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import { ChartDataService } from '@app/backend/chart-data.service';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { ChartDataInterface, FielderAnalyticsInterface } from "@app/models/chart-data.model";
import * as Highcharts from 'highcharts/highstock';

import * as charts from '@utilities/highchart-objectify';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-drilled-holes-by-drilled-length',
	templateUrl: './drilled-holes-by-drilled-length.component.html',
	styleUrls: ['./drilled-holes-by-drilled-length.component.scss']
})
export class DrilledHolesByDrilledLengthComponent implements OnInit, OnDestroy {

	public Highcharts: typeof Highcharts = Highcharts; // required
	public chartOptions = []; // required
	private subscriptions: Subscription = new Subscription();

	public loading:boolean;

	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string

	private holeAndCount:ChartDataInterface[] = [];

	constructor(
		private chartDataService:ChartDataService,
		private generateChartDataService:GenerateChartDataService,
		private siteService: SiteService,
		private userService: UserService) {
	}

	ngOnInit() {
		this.getReport();
		
		this.subscriptions.add(this.chartDataService.loading$.subscribe(loadingCount => {
			this.loading = !!loadingCount;
		}));

		this.subscriptions.add(this.chartDataService.fielderAnalytics$.subscribe((res) => {
			this.triggerLoadData(res);
		}));

		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.subDomainId$.subscribe(subdomainId => {
			this.subdomainId = subdomainId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.siteId$.subscribe(siteId => {
			this.siteId = siteId;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.startDate$.subscribe(startDate => {
			this.startDate = startDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.endDate$.subscribe(endDate => {
			this.endDate = endDate;
			this.triggerLoadData();
		}));

		this.subscriptions.add(this.siteService.selectedTimeline$.subscribe(selectedTimeline => {
			this.selectedTimeline = selectedTimeline;
			this.triggerLoadData();
		})); 

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe(() => {
			this.triggerLoadData();
		}));
	}

	private async getReport() {
		this.subscriptions.add(this.chartDataService.getCacheFielderAnalytics().subscribe((res) => {
			this.triggerLoadData(res);
		}));	
	}

	private loadData = (chartData: ChartDataInterface[])=> {
		this.holeAndCount = chartData;
		const data:FielderAnalyticsInterface = this.generateChartDataService.generateChartData(this.holeAndCount);
		for (const key in data?.totalHolesAndTotalDrilledHolesByShot) {
			this.chartOptions = charts.generateChartOptions(data?.totalHolesAndTotalDrilledHolesByShot[key]);
		}
	}

	private triggerLoadData(data? : ChartDataInterface[]) {
		const originalFielderAnalytics = data || this.generateChartDataService.originalFielderAnalytics;
		this.holeAndCount = this.generateChartDataService.getFilteredData(
			originalFielderAnalytics,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		);

		this.loadData(this.holeAndCount);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

}
